import { template as template_b614749c418f4757ac2b19b48f6a9f2a } from "@ember/template-compiler";
const FKControlMenuContainer = template_b614749c418f4757ac2b19b48f6a9f2a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
