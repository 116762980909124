import { template as template_8033a0b50c3d4e7fa5524ae6a9a42f1a } from "@ember/template-compiler";
const WelcomeHeader = template_8033a0b50c3d4e7fa5524ae6a9a42f1a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
